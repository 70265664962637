<template>
  <div
    v-if="!isTourismVoucher"
    class="bg-color flex flex-shrink-0 voucher-card-container"
    :class="{'flip': isFlip}"
    :style="{
      '--bgColor': !isFlip ? 'transparent' : 'white',
      '--card-description-background': voucher.data_json != null ? voucher.data_json.background_description_color : voucher.background_description_color,
      '--card-description-color': voucher.data_json != null ? voucher.data_json.description_color : voucher.description_color,
      '--card-header-footer-background': voucher.data_json != null ? voucher.data_json.header_and_footer_background_color : voucher.header_and_footer_background_color,
      '--card-header-footer-color': voucher.data_json != null ? voucher.data_json.header_and_footer_color : voucher.header_and_footer_color,
      fontSize: 'clamp(4px, 4.360vw, 16px)'
    }"
  >
    <div
      class="flex flex-col w-full bg-aid rounded"
      :style="{
        '--bgAid': !isFlip
          ? (userVoucher && userVoucher.background_aid)
            ? userVoucher.background_aid
            : voucher.background_aid
          : ''
      }"
    >
      <CardInfo
        v-if="!isAction"
        :class="[ onGetTextColor() ]"
        :voucher="voucher"
        :order="order"
        :qr="qr"
        :userVoucher="userVoucher"
        :isFlippable="isFlippable"
        :withQR="withQR"
        :listId="listId"
        :cardId="cardId"
        :isBought="isBought"
        :asGift="asGift"
        :isSpecialVoucher="(isSpecialVoucher)"
        :hideReminder="hideReminder"
        @onFlip="onFlip()"
      />
      <CardAction
        v-if="isAction && role === 'seller'"
        :voucher="voucher"
        @onFlip="onFlip()"
      />
      <CardUserAction
        v-if="isAction && role === 'user'"
        :qr="qr"
        :userVoucher="userVoucher"
        :order="order"
        @onFlip="onFlip()"
      />
    </div>
  </div>

  <div
    v-else
    class="flex justify-center"
  >
    <div class="w-80 bg-white rounded-t-lg">
      <div
        v-if="!claimTourism"
        :style="{
          backgroundImage: 'url(' +
                onSetCustomImage(
                  voucher.data_json != null &&
                    voucher.data_json.background_image
                    ? voucher.data_json.background_image
                    : voucher.custom_background_image ||
                        voucher.background_image
                ) +
                ')',
          backgroundSize: 'cover',
        }"
        style="background-position: center center; background-repeat: no-repeat;"
        class="w-80 h-80 rounded-t-lg"
      ></div>

      <div
        v-else
        class="w-80 h-80 rounded-t-lg flex flex-col justify-center"
      >
        <QrcodeVue
          class="h-auto mx-auto mb-5"
          :value="`${voucher.employee_claim_no}${AUTH_USER.data.claim_no}`"
          level="H"
          :size="200"
        />
        <span
          v-if="voucher.employee_claim_no && AUTH_USER.data.claim_no"
          class="font-bold text-center"
        >
          {{ `${voucher.employee_claim_no}${AUTH_USER.data.claim_no}` }}
        </span>
      </div>

      <div class="px-5 pt-5">
        <div class="text-lg font-semibold">{{ voucher.title || 'Gutscheintitel' }}</div>
        <div class="text-base">{{ (voucher.seller && voucher.seller.username) || 'N/A' }}</div>

        <hr class="mt-3 mb-5">

        <div class="flex gap-3">
          <div class="flex flex-col w-1/2">
            <div class="text-xs">Preis</div>
            <div class="text-base font-semibold">
              {{ voucher.employee_value || '1 Person' }}
            </div>
          </div>

          <div class="flex gap-1 w-1/2">
            <QrcodeVue
              class="card-qr"
              :value="'https://epasnets.com/login'"
              level="H"
            />
            <div class="flex flex-col ml-2 mt-3">
              <span class="text-2xs">Gutscheincode</span>
              <span
                v-if="voucher.employee_claim_no && AUTH_USER.data.claim_no"
                class="font-bold text-xs"
              >
                {{ `${voucher.employee_claim_no}${AUTH_USER.data.claim_no}` }}
              </span>
              <span
                v-else
                class="font-bold text-xs"
              >
                123456789
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="showTourismButton"
          class="flex py-2 mb-4"
        >
          <button
            type="button"
            class="cursor-pointer py-3 rounded-lg text-white text-xs w-full"
            style="background: var(--company-color, #1D4F55)"
            @click="showCode"
          >
            Gutschein einlösen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import CardInfo from './CardInfo'
  import CardAction from './CardAction'
  import CardUserAction from './CardUserAction'
  import { post } from '_helpers/ApiService'
  import QrcodeVue from 'qrcode.vue'

  export default {
    components: {
      CardInfo,
      CardAction,
      CardUserAction,
      QrcodeVue
    },
    props: {
      cardId: {
        type: String,
        default: 'voucher-card'
      },
      listId: {
        type: String,
        default: 'voucher-list'
      },

      voucher: {
        type: Object,
        default: null
      }, order: {
        type: Object,
        default: null
      }, qr: {
        type: Object,
        default: null
      }, userVoucher: {
        type: Object,
        default: null
      },

      bg: {
        type: String,
        default: '#fff'
      }, role: {
        type: String,
        default: 'seller'
      }, bgImg: {
        type: String,
        default: ''
      }, isFlippable: {
        type: Boolean,
        default: true
      }, withQR: {
        type: Boolean,
        default: true
      },
      isBought: {
        type: Boolean,
        default: false
      },
      asGift: {
        type: Boolean,
        default: false
      },
      isSpecialVoucher: {
        type: Boolean,
        default: false
      },
      hideReminder: {
        type: Boolean,
        default: false
      },
      isTourismVoucher: {
        type: Boolean,
        default: false
      },
      showTourismButton: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        isAction: false,
        isFlip: false,
        vIndex: 0,
        claimTourism: false
      };
    },
    computed: {
      AUTH_USER() {
        return this.$store.getters.AUTH_USER;
      },
    },
    watch: {
      'voucher.background_image'(newVal, oldVal)
      {
        if( newVal & newVal != '') {
          this.onSetBgImage(newVal)
        }
      },
      'userVoucher.template'(newVal, oldVal)
      {
        if( newVal && newVal.length > 0 ) {
          const tem = newVal.filter( row => row.status )
          if( tem.length > 0 ) {
            this.onSetBgImage( tem[0].path )
          }
        }
      },
      'userVoucher.text_color'(newVal, oldVal)
      {
        this.onGetTextColor()
      },
    },
    mounted() {
      console.log(this.voucher)
      this.onSetBgImage( this.onGetBg() )
    },
    activated() {
      this.onSetBgImage( this.onGetBg() )
    },
    methods: {
      onFlip(walletDetails = null)
      {
        if(this.isFlippable && !this.asGift) {
          this.$router.push(`/voucher/${this.userVoucher.id}`)
          //  if (this.isFlippable) {
              // this.isFlip = !this.isFlip;
              // this.isAction = !this.isAction;
              // this.onSetBgImage(this.isFlip ? '' : this.onGetBg());
          //   }
        }

        if(this.asGift) {
          const auth = this.AUTH_USER;

          if(auth.isAuth) {
            if(auth.data.user_role.role.name == 'user') {
              const email = {
                'subject': `${this.order.voucher.title} - Voucher Request`,
                'from': `Username: ${auth.data.username} (${auth.data.email})`,
                'order_info': this.order,
                'voucher_info': this.userVoucher
              }

              this.$swal({
                title: 'Gutschein anfragen',
                text: `Willst du diesen Gutschein als Geschenk erhalten?`,
                showCancelButton: true,
                confirmButtonColor: '#48BB78',
                cancelButtonColor: '#FC8181',
                confirmButtonText: 'Ja',
                cancelButtonText: 'Nein',
              }).then((result) => {
                if (result.value) {
                  this.sendGiftRequest(email)
                }
              });
            }
          } else {
            this.$router.push(`https://epasnets.com/login`);
          }
        }
      },
      flip() {
        this.isFlip = !this.isFlip;
        this.isAction = !this.isAction;
        this.onSetBgImage(this.isFlip ? '' : this.onGetBg());
        window.scrollTo({top: 100, behavior: 'smooth'});
      },
      onGetTextColor()
      {
        let color = ''
        if( this.userVoucher?.text_color != null  ) {
          color = (this.userVoucher.text_color == 'dark') ? 'text-black' : 'text-white'
        } else {
          color = (this.voucher.text_color == 'dark') ? 'text-black' : 'text-white'
        }
        return color
      },
      onGetBg() {
        let bg = '';
        if( this.userVoucher?.template ) {
          const template = this.userVoucher.template
          bg = template.image
        } else if(this.voucher?.background_image) {
          bg = this.voucher.background_image
        }
        return bg
      },
      onSetBgImage(value)
      {
        const card = document.getElementById(`${this.listId}-${this.cardId}`)
        if(card) {
          const bg = (this.voucher && this.voucher.id && (value.search('base64') < 0))
            ? (value != '') ? `${process.env.VUE_APP_API_BASE_URL}/storage/${value}` : ''
            : value
          card.style.backgroundImage = `url('${bg}')`
          card.style.backgroundSize = `cover`
        }
      },
      async sendGiftRequest(email) {
        await post(`gift-request`, email)
      },
      onSetCustomImage(value) {
        if (!value) return '';
        return value.search('base64') < 0
          ? `${process.env.VUE_APP_API_BASE_URL}/storage/${value}`
          : value;
      },
      showCode() {
        this.claimTourism = !this.claimTourism
      }
    }
  }
</script>
<style lang="css" scoped>
  .voucher-card-container {
    max-width: 367px;
    width: 100%;
    min-height: 330px;
    /* min-height: 480px; */
    transform: rotateY(0deg);
    transition: transform 0.5s linear;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
  }
  .voucher-card-container.flip {
    transform: rotateY(360deg);
    transition: transform 0.5s linear;
  }
  .card-header {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
  }
  .card-content {
    padding: 8px 0px;
  }
  .card-logo {
    width: 65px;
    height: 30px;
    margin-left: auto;
  }
  .card-qr {
    width: 70px;
    height: 70px;
    margin-left: auto;
  }
  .qr-text {
    margin-top: -10px;
    margin-left: 9px;
    font-size: 9px;
  }
  .bg-color {
    background-color: var(--bgColor);
  }
  .bg-aid {
    background-color: var(--bgAid);
  }
  @media only screen and (max-width: 600px) {
    .voucher-card-container {
      width: 99%;
      max-width: 330px;
    }
  }
</style>