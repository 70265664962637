var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isTourismVoucher)?_c('div',{staticClass:"bg-color flex flex-shrink-0 voucher-card-container",class:{'flip': _vm.isFlip},style:({
    '--bgColor': !_vm.isFlip ? 'transparent' : 'white',
    '--card-description-background': _vm.voucher.data_json != null ? _vm.voucher.data_json.background_description_color : _vm.voucher.background_description_color,
    '--card-description-color': _vm.voucher.data_json != null ? _vm.voucher.data_json.description_color : _vm.voucher.description_color,
    '--card-header-footer-background': _vm.voucher.data_json != null ? _vm.voucher.data_json.header_and_footer_background_color : _vm.voucher.header_and_footer_background_color,
    '--card-header-footer-color': _vm.voucher.data_json != null ? _vm.voucher.data_json.header_and_footer_color : _vm.voucher.header_and_footer_color,
    fontSize: 'clamp(4px, 4.360vw, 16px)'
  })},[_c('div',{staticClass:"flex flex-col w-full bg-aid rounded",style:({
      '--bgAid': !_vm.isFlip
        ? (_vm.userVoucher && _vm.userVoucher.background_aid)
          ? _vm.userVoucher.background_aid
          : _vm.voucher.background_aid
        : ''
    })},[(!_vm.isAction)?_c('CardInfo',{class:[ _vm.onGetTextColor() ],attrs:{"voucher":_vm.voucher,"order":_vm.order,"qr":_vm.qr,"userVoucher":_vm.userVoucher,"isFlippable":_vm.isFlippable,"withQR":_vm.withQR,"listId":_vm.listId,"cardId":_vm.cardId,"isBought":_vm.isBought,"asGift":_vm.asGift,"isSpecialVoucher":(_vm.isSpecialVoucher),"hideReminder":_vm.hideReminder},on:{"onFlip":function($event){return _vm.onFlip()}}}):_vm._e(),(_vm.isAction && _vm.role === 'seller')?_c('CardAction',{attrs:{"voucher":_vm.voucher},on:{"onFlip":function($event){return _vm.onFlip()}}}):_vm._e(),(_vm.isAction && _vm.role === 'user')?_c('CardUserAction',{attrs:{"qr":_vm.qr,"userVoucher":_vm.userVoucher,"order":_vm.order},on:{"onFlip":function($event){return _vm.onFlip()}}}):_vm._e()],1)]):_c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"w-80 bg-white rounded-t-lg"},[(!_vm.claimTourism)?_c('div',{staticClass:"w-80 h-80 rounded-t-lg",staticStyle:{"background-position":"center center","background-repeat":"no-repeat"},style:({
        backgroundImage: 'url(' +
              _vm.onSetCustomImage(
                _vm.voucher.data_json != null &&
                  _vm.voucher.data_json.background_image
                  ? _vm.voucher.data_json.background_image
                  : _vm.voucher.custom_background_image ||
                      _vm.voucher.background_image
              ) +
              ')',
        backgroundSize: 'cover',
      })}):_c('div',{staticClass:"w-80 h-80 rounded-t-lg flex flex-col justify-center"},[_c('QrcodeVue',{staticClass:"h-auto mx-auto mb-5",attrs:{"value":("" + (_vm.voucher.employee_claim_no) + (_vm.AUTH_USER.data.claim_no)),"level":"H","size":200}}),(_vm.voucher.employee_claim_no && _vm.AUTH_USER.data.claim_no)?_c('span',{staticClass:"font-bold text-center"},[_vm._v(" "+_vm._s(("" + (_vm.voucher.employee_claim_no) + (_vm.AUTH_USER.data.claim_no)))+" ")]):_vm._e()],1),_c('div',{staticClass:"px-5 pt-5"},[_c('div',{staticClass:"text-lg font-semibold"},[_vm._v(_vm._s(_vm.voucher.title || 'Gutscheintitel'))]),_c('div',{staticClass:"text-base"},[_vm._v(_vm._s((_vm.voucher.seller && _vm.voucher.seller.username) || 'N/A'))]),_c('hr',{staticClass:"mt-3 mb-5"}),_c('div',{staticClass:"flex gap-3"},[_c('div',{staticClass:"flex flex-col w-1/2"},[_c('div',{staticClass:"text-xs"},[_vm._v("Preis")]),_c('div',{staticClass:"text-base font-semibold"},[_vm._v(" "+_vm._s(_vm.voucher.employee_value || '1 Person')+" ")])]),_c('div',{staticClass:"flex gap-1 w-1/2"},[_c('QrcodeVue',{staticClass:"card-qr",attrs:{"value":'https://epasnets.com/login',"level":"H"}}),_c('div',{staticClass:"flex flex-col ml-2 mt-3"},[_c('span',{staticClass:"text-2xs"},[_vm._v("Gutscheincode")]),(_vm.voucher.employee_claim_no && _vm.AUTH_USER.data.claim_no)?_c('span',{staticClass:"font-bold text-xs"},[_vm._v(" "+_vm._s(("" + (_vm.voucher.employee_claim_no) + (_vm.AUTH_USER.data.claim_no)))+" ")]):_c('span',{staticClass:"font-bold text-xs"},[_vm._v(" 123456789 ")])])],1)]),(_vm.showTourismButton)?_c('div',{staticClass:"flex py-2 mb-4"},[_c('button',{staticClass:"cursor-pointer py-3 rounded-lg text-white text-xs w-full",staticStyle:{"background":"var(--company-color, #1D4F55)"},attrs:{"type":"button"},on:{"click":_vm.showCode}},[_vm._v(" Gutschein einlösen ")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }